import * as React from "react"
import { graphql } from "gatsby"

// components
import Layout from '../components/layout'
import { Link } from "gatsby"
import ContactForm from '../components/contact-form'

// images
import QuadranteVericode from '../images/quadrante-vericode.png'
import IsgLogo from '../images/isg-logo.png'
import IconVericode from '../images/icon.png'
import { IconArrowRight } from "../components/icons"
import { Trans } from 'react-i18next'

// seo
const seo = {
  title: 'seo.isg.title',
  description: 'seo.isg.description'
}

const LandingPage = () => {
  return (
    <>
      <Layout headerStyle="simple" seo={seo}>
        <section className="flex items-center text-white section section--fade bg-primary lg:min-h-screen bg-landing">
          <div className="container py-xl">
            <div className="lg:w-2/3">
              <h1 className="text-3xl leading-snug text-white lg:text-5xl lg:leading-tight">
                <Trans i18nKey='hero.title' components={{ strong: <strong /> }} />
              </h1>
              <p className="lead"><Trans i18nKey='hero.lead' /></p>

              <div className="lg:flex">
                <div className="border text-primary p-sm" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
                  <strong>Rising Star</strong>
                  <small className="block text-xs">Continuous Testing Specialist</small>
                </div>
                <div className="-mt-px border text-primary p-sm lg:m-0 lg:border-l-0" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
                  <strong>Market Challenger</strong>
                  <small className="block text-xs">Application quality Assurance</small>
                </div>
              </div>

              <a href="#relatorio" className="button button--inverted mt-lg">
                <span><Trans i18nKey='hero.button' /></span>
              </a>
            </div>
          </div>
        </section>

        <section className="mb-0 section">
          <div className="container">
            <blockquote className="quote quote--block">
              <span>
                <Trans i18nKey='quote.message' />
                <small><Trans i18nKey='quote.by' /></small>
              </span>
            </blockquote>

            <img className="mx-auto rounded-lg shadow-lg my-lg" alt='Quadrante Vericode' src={QuadranteVericode} />
          </div>

          <div id="relatorio" className="container mb-md">
            <div className="grid items-center gap-10 bg-gray-200 rounded-lg shadow-lg xl:grid-cols-2 p-lg">
              <div>
                <h2 className="title title--display"><Trans i18nKey='section.primary.title' /></h2>
              </div>
              <ContactForm
                formType="download"
                type="landing"
                direction="column"
                showTexts={false}
                title="section.contact-form.title"
                btnLabel="section.contact-form.btnlabel"
              />
            </div>
          </div>
        </section>

        <section className="mb-xl">
          <div className="container">
            <div className="block mx-auto lg:w-2/3 mb-lg">
              <p className="text-center lead">
                <Trans i18nKey='section.secondary.lead' />
              </p>
            </div>

            <div className="grid text-center lg:grid-cols-3 gap-x-10">
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/devsecops">
                <Trans i18nKey='section.secondary.table.first' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/">
                <Trans i18nKey='section.secondary.table.second' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/analise-de-codigo-fonte">
                <Trans i18nKey='section.secondary.table.third' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/testes-automatizados">
                <Trans i18nKey='section.secondary.table.fourth' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/avaliacoes-nao-funcionais">
                <Trans i18nKey='section.secondary.table.fifth' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/testes-de-seguranca">
                <Trans i18nKey='section.secondary.table.sixth' /></Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/virtualizacao-de-servicos">
                <Trans i18nKey='section.secondary.table.seventh' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/capacidade">
                <Trans i18nKey='section.secondary.table.eighth' />
              </Link>
              <Link className="inline-flex items-center justify-center h-16 -mt-px border p-xs hover:underline" to="/servicos/sre">
                <Trans i18nKey='section.secondary.table.ninth' />
              </Link>
            </div>
          </div>
        </section>

        <hr className="divider" />

        <section className="section section--light">
          <div className="container">
            <div className="mx-auto text-center lg:w-2/3 pt-lg">
              <img src={IconVericode} width="150" alt='Icone Vericode' className="mx-auto mb-md" />
              <h2 className="title title--display">
                <Trans i18nKey='section.tertiary.title' />
              </h2>
              <p>
                <Trans i18nKey='section.tertiary.lead' />
              </p>

              <a rel="noreferrer" target="_blank" href="//dot.page.vericode.com.br" className="card__action button button--pink button--nude">
                <span>
                  <Trans i18nKey='section.tertiary.btnlabel' />
                </span>
                <IconArrowRight className="icon" />
              </a>
            </div>
          </div>
        </section>

        <hr className="divider" />

        <section className="section">
          <div className="container">
            <h2 className="title title--display"><Trans i18nKey='section.quaternary.title' /></h2>
            <div className="lg:flex lg:space-x-10">
              <div className="h-64 bg-gray-200 bg-center bg-no-repeat rounded-lg lg:w-96 mb-sm lg:h-96 xl:h-auto" style={{ backgroundImage: `url(${IsgLogo})`, backgroundSize: 300 }} />

              <div className="flex-1">
                <p>
                  <Trans i18nKey='section.quaternary.lead' />
                  <a className="button button--nude" href="//www.isg-one.com">www.isg-one.com</a>.
                </p>
              </div>
            </div>
          </div>
        </section>

        <hr className="divider" />

        <section className="text-center section section--light -mb-lg">
          <h3 className="mb-0 mt-md">
            <Trans i18nKey='section.report.title' />
          </h3>
          <a href="#relatorio" className="border button button--inverted mt-md">
            <Trans i18nKey='section.report.btnlabel' />
          </a>
        </section>
      </Layout>
    </>
  )
}

export default LandingPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["isg", "layout", "header", "footer", "contact-form"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;